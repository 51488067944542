<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!user">
      <span class="loader"></span>
    </span>
    <div v-if="user" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <user-view-global :user="user" @onGenerateToken="generateToken" />
        </tab-pane>

        <tab-pane
          title="authorizations"
          id="2"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PERMISSIONS)"
        >
          <span slot="title">
            <i class="ni ni-key-25"></i>
            {{ $t("USERS.AUTHORIZATIONS") }}
          </span>
          <user-view-authorizations :user="user" />
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_FILES) &&
            user.organization
          "
        >
          <span slot="title">
            <i class="ni ni-folder-17"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="user" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <user-view-logs :user="user" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import UserViewGlobal from "../partials/UserViewGlobal.vue";
import UserViewAuthorizations from "../partials/UserViewAuthorizations.vue";
import UserViewLogs from "../partials/UserViewLogs.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    UserViewGlobal,
    UserViewAuthorizations,
    UserViewLogs,
    ListFileComponent,
  },

  mixins: [requestErrorMixin],

  props: {
    userId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      user: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("users/get", this.userId);
        this.user = this.$store.getters["users/user"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editUser(row) {
      this.$router.push({
        name: "Edit User",
        params: { id: this.user.id },
      });
    },

    async deleteUser() {
      const confirmation = await swal.fire({
        title: this.$t("USERS.DELETE_THIS_USER"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("users/destroy", this.user.id);
          this.$notify({
            type: "success",
            message: this.$t("USERS.USER_DELETED"),
          });
          await this.goBack();
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    goBack() {
      this.$router.push({ name: "List Users" });
    },

    async activateUser() {
      try {
        this.loading = true;
        await this.$store.dispatch("users/activate", this.user.id);
        this.user = this.$store.getters["users/user"];
        this.$notify({
          type: "success",
          message: this.$t("USERS.USER_ACTIVATED"),
        });
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async deactivateUser() {
      try {
        this.loading = true;
        await this.$store.dispatch("users/deactivate", this.user.id);
        this.user = this.$store.getters["users/user"];
        this.$notify({
          type: "success",
          message: this.$t("USERS.USER_DEACTIVATED"),
        });
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },
};
</script>
